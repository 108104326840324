<template>
  <div class="size-guide">
    <header-page-derek :title="'Guía de tallas'" :skeleton="false" />
    <div class="container-size-guide container-max-width">
      <div v-if="videos" class="mb-5 d-flex flex-wrap w-100">
        <div class="col-12 col-md-5 container-detail-size-guide">
          <div class="header-detail-size-guide">
            <h2>Prendas superiores</h2>
          </div>
          <div class="pe-0 pe-sm-3 h-100">
            <h6>¿CÓMO MEDIR MI TALLA EN PRENDAS INFERIORES?</h6>
            <p>
              <b>1.</b> Pecho, rodea la parte con más volumen. <br />
              <b>2.</b> Cintura, rodea la parte más estrecha.
            </p>
            <button class="btn-play-video">
              <div
                class="container-play-video"
                @click="toggleVideo('video_superiror')"
              >
                <img src="@/assets/icons/play-video.svg" alt="" />
                <p>Mira como medir</p>
              </div>
            </button>
            <div>
              <video id="video_superiror" width="0" height="0" muted>
                <source :src="videos.prenda_superior" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 container-table">
          <div style="overflow: hidden;">
            <b-table
              striped
              hover
              borderless
              :items="dataSizes[0].sizes"
            ></b-table>
          </div>
        </div>
      </div>
      <div v-if="videos" class="mb-5 d-flex flex-wrap w-100">
        <div class="col-12 col-md-5 container-detail-size-guide">
          <div class="header-detail-size-guide">
            <h2>Prendas inferiores</h2>
          </div>
          <div class="pe-0 pe-sm-3 h-100">
            <h6>¿CÓMO MEDIR MI TALLA EN PRENDAS INFERIORES?</h6>
            <p>
              <b>1.</b> Cintura, rodea la parte más estrecha.<br />
              <b>2.</b> Cadera, rodea la parte más ancha mientras estás de pie
              con los pies juntos.
            </p>
            <button class="btn-play-video">
              <div
                class="container-play-video"
                @click="toggleVideo('video_inferior')"
              >
                <img src="@/assets/icons/play-video.svg" alt="" />
                <p>Mira como medir</p>
              </div>
            </button>
            <div>
              <video id="video_inferior" width="0" height="0" muted>
                <source :src="videos.prenda_inferior" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 container-table">
          <div style="overflow: hidden;">
            <b-table
              striped
              hover
              borderless
              :items="dataSizes[1].sizes"
            ></b-table>
          </div>
        </div>
      </div>
      <div v-if="videos" class="d-flex flex-wrap w-100">
        <div class="col-12 col-md-5 container-detail-size-guide">
          <div class="header-detail-size-guide">
            <h2>Calzado</h2>
          </div>
          <div class="pe-0 pe-sm-3 h-100">
            <h6>¿CÓMO MEDIR MI TALLA EN CALZADO?</h6>
            <p>
              <b>1.</b> Longitud del pie: Colócate junto a una pared, con
              medias, con los talones junto a la pared. <br />
              <b>2.</b> Coloca tu pie en una superficie dura sobre una hoja de
              papel blanca con el talón contra la pared. <br />
              <b>3.</b> Marca con un lápiz desde tu talón hasta dónde llega tu
              dedo más largo. <br />
              <b>4.</b> Mide la distancia de tu pie marcada en la hoja, desde el
              borde de la hoja hasta la punta del pie en centímetros (cm).
            </p>
            <button class="btn-play-video">
              <div
                class="container-play-video"
                @click="toggleVideo('video_calzado')"
              >
                <img src="@/assets/icons/play-video.svg" alt="" />
                <p>Mira como medir</p>
              </div>
            </button>
            <div>
              <video id="video_calzado" width="0" height="0" muted>
                <source :src="videos.calzado" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 container-table">
          <div style="overflow: hidden;">
            <b-table
              striped
              hover
              borderless
              :items="dataSizes[2].sizes"
            ></b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'

import { mapState } from 'vuex'
export default {
  components: { HeaderPageDerek },
  data() {
    return {
      videos: null,
      items: [
        { talla: 'XS', 'pecho (cm)': '86-88', 'Cintura (cm)': '64-66' },
        { talla: 'S', 'pecho (cm)': '90-92', 'Cintura (cm)': '68-70' },
        { talla: 'M', 'pecho (cm)': '94-96', 'Cintura (cm)': '72-74' },
        { talla: 'L', 'pecho (cm)': '98-100', 'Cintura (cm)': '76-78' },
        { talla: 'XL', 'pecho (cm)': '102-104', 'Cintura (cm)': '80-82' },
      ],
      items2: [
        { talla: '4', 'Cintura (cm)': '60-62', 'Cadera (cm)': '86-88' },
        { talla: '6', 'Cintura (cm)': '64-66', 'Cadera (cm)': '90-92' },
        { talla: '8', 'Cintura (cm)': '68-70', 'Cadera (cm)': '94-96' },
        { talla: '10', 'Cintura (cm)': '72-74', 'Cadera (cm)': '98-100' },
        { talla: '12', 'Cintura (cm)': '76-78', 'Cadera (cm)': '102-104' },
        { talla: '14', 'Cintura (cm)': '80-82', 'Cadera (cm)': '106-108' },
        { talla: '16', 'Cintura (cm)': '84-86', 'Cadera (cm)': '110-112' },
      ],
      items3: [
        { CM: '22.5', COL: '34', US: '4' },
        { CM: '23', COL: '35', US: '5' },
        { CM: '23.5', COL: '36', US: '6' },
        { CM: '24', COL: '37', US: '7' },
        { CM: '24.5', COL: '38', US: '8' },
        { CM: '25', COL: '39', US: '9' },
        { CM: '25.5', COL: '40', US: '9.5' },
      ],
    }
  },
  computed: {
    ...mapState('products', ['dataSizes']),
  },
  async mounted() {
    document.title = 'Guía de tallas | Derek Tienda Online'
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/layouts/get_guia_tallas',
    })
      .then(response => {
        this.videos = response.data.videos.mujer
      })
      .catch(e => {
        console.log(e)
      })
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    toggleVideo(id_video) {
      let video = document.getElementById(id_video)
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      }
      video.play()
      video.currentTime = 0
      video.controls = true
      this.listenerExitVideo(id_video)
    },
    async listenerExitVideo(id_video) {
      let video = document.getElementById(id_video)
      await setTimeout(() => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitCurrentFullScreenElement
        ) {
          this.listenerExitVideo(id_video)
        } else {
          video.pause()
        }
      }, 1000)
    },
  },
}
</script>
